import React, { useState, useEffect, useRef } from "react";
import { submitCampaign } from "../../../api/SubmitCampaign";
import QRCodeModal from "../QRCodeModal/QRCodeModal";
import { urlifyName } from "../../shared/Utils/PageUrl";
import classes from "./CampaignModal.module.css";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { lineBreaks } from "../../shared/Utils/LineBreaks";
import SharedTooltip from "../../shared/Tooltip/SharedTooltip";
import Quill from "quill";
import Editor from "./Editor/Editor";
import Compressor from "compressorjs";
import CampaignFeature from "../../Campaigns/CampaignFeature/CampaignFeature";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import AnimalCampaignForm from "./AnimalCampaignForm";
import ProductCampaignForm from "./ProductCampaignForm";
import CapitalCampaignForm from "./CapitalCampaignForm";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { Grid, Card, CardMedia } from "@mui/material";

const maxSize = 10 * 1024 * 1024;

const SortableItem = SortableElement(({ value }) => (
  <Grid item xs={4}>
    <Card>
      <CardMedia
        component="img"
        sx={{ height: 110, objectFit: "cover" }}
        image={value}
        alt="campaign image"
      />
    </Card>
  </Grid>
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <div>
      <Grid container spacing={2} className="overflow-auto">
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} value={value} />
        ))}
      </Grid>
    </div>
  );
});

const baseCampaignFields = {
  nonprofit: "",
  campaign_title: "",
  description: "",
  donation_goal: "",
  donation_box_title: "",
  video_urls: "",
  images: [],
};
const baseCampaignErrors = {
  nonprofit: "",
  campaign_title: "",
  description: "",
  donation_goal: "",
  donation_box_title: "",
  video_urls: "",
  images: "",
};

const CampaignModal = ({
  isOpen,
  onClose,
  nonprofitId,
  nonprofitName,
  setCampaigns,
  setQrModalOpen,
  campaignUrl,
  setCampaignUrl,
  campaignType,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [graphicImagePreviews, setGraphicImagePreviews] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const allowedFields =
    campaignType === "Animal"
      ? [
          "campaign_type",
          "campaign_title",
          "description",
          "donation_goal",
          "donation_box_title",
          "video_urls",
          "animal_name",
          "age",
          "color",
          "breed",
          "gender",
          "species",
          "images",
          "graphic_images",
        ]
      : campaignType === "Product"
      ? [
          "campaign_type",
          "campaign_title",
          "description",
          "donation_goal",
          "donation_box_title",
          "video_urls",
          "product_type",
          "product_quantity_needed",
          "images",
        ]
      : campaignType === "Capital"
      ? [
          "campaign_type",
          "campaign_title",
          "description",
          "donation_goal",
          "donation_box_title",
          "video_urls",
          "land_area",
          "land_location",
          "project_status",
          "estimated_completion_date",
          "images",
        ]
      : [];

  // Form values for Animal, Product, and Capital campaigns
  const [campaignData, setCampaignData] = useState({
    ...baseCampaignFields,
    campaign_type: "animal",
    animal_name: "",
    age: "",
    color: "",
    breed: "",
    gender: "",
    species: "",
    graphic_images: [],
    success: "",
    error: "",
  });

  const [animalFormValues, setAnimalFormValues] = useState({
    ...baseCampaignFields,
    campaign_type: "animal",
    animal_name: "",
    age: "",
    color: "",
    breed: "",
    gender: "",
    species: "",
    graphic_images: [],
    success: "",
    error: "",
  });
  const [animalFormErrors, setAnimalFormErrors] = useState({
    ...baseCampaignErrors,
    animal_name: "",
    age: "",
    color: "",
    breed: "",
    gender: "",
    species: "",
    graphic_images: [],
  });

  const [productFormValues, setProductFormValues] = useState({
    ...baseCampaignFields,
    campaign_type: "product",
    product_type: "",
    product_quantity_needed: "",
  });
  const [productFormErrors, setProductFormErrors] = useState({
    ...baseCampaignErrors,
    product_type: "",
    product_quantity_needed: "",
  });

  const [capitalFormValues, setCapitalFormValues] = useState({
    ...baseCampaignFields,
    campaign_type: "capital",
    land_area: "",
    land_location: "",
    project_status: "",
    estimated_completion_date: "",
  });

  const [capitalFormErrors, setCapitalFormErrors] = useState({
    baseCampaignErrors,
    land_area: "",
    land_location: "",
    project_status: "",
    estimated_completion_date: "",
  });

  useEffect(() => {
    if (campaignUrl !== "") {
      setQrModalOpen(true); // Open the modal only after campaignUrl is updated
    }
  }, [campaignUrl]);

  // Attaches NP id to all form values
  useEffect(() => {
    if (campaignType === "Animal") {
      setAnimalFormValues((prevState) => ({
        ...prevState,
        nonprofit: nonprofitId,
      }));
    } else if (campaignType === "Product") {
      setProductFormValues((prevState) => ({
        ...prevState,
        nonprofit: nonprofitId,
      }));
    } else if (campaignType === "Capital") {
      setCapitalFormValues((prevState) => ({
        ...prevState,
        nonprofit: nonprofitId,
      }));
    }
  }, [campaignType]);

  if (!isOpen) return null;

  const handleImageChange = (event, setFormErrors, setFormValues) => {
    const files = Array.from(event.target.files);
    let isTooLarge = false;
    setFormErrors((prevState) => ({
      ...prevState,
      images: "",
    }));

    const validFiles = files.filter((file) => {
      if (file.size > maxSize) {
        setFormErrors((prevState) => ({
          ...prevState,
          images:
            "File size is too large (Compressed File must be under 10 MB).",
        }));
        isTooLarge = true;
        return false;
      }
      return true;
    });

    if (!isTooLarge) {
      const compressFiles = async (validFiles) => {
        const compressedFiles = await Promise.all(
          validFiles.map(async (file) => {
            return new Promise((resolve) => {
              //Compressor is async for success
              new Compressor(file, {
                quality: 0.2,
                success: (compressedResult) => {
                  resolve(compressedResult);
                },
              });
            });
          })
        );

        return compressedFiles;
      };

      const handleFileCompression = async (validFiles) => {
        try {
          validFiles = await compressFiles(validFiles);
          const newFiles = [];
          validFiles.forEach((blob) => {
            newFiles.push(
              new File([blob], blob.name, {
                type: blob.type,
              })
            );
          });

          const newImages = newFiles.map((file) => ({
            file,
            url: URL.createObjectURL(file),
          }));

          setFormValues((prevState) => ({
            ...prevState,
            images: [...prevState.images, ...newImages],
          }));

          const imagePreviews = newFiles.map((file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            return new Promise((resolve) => {
              reader.onloadend = () => {
                resolve(reader.result);
              };
            });
          });

          Promise.all(imagePreviews).then((previews) => {
            setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
          });
        } catch (error) {
          console.error("Error during file compression:", error);
        }
      };
      handleFileCompression(validFiles);
    }
  };

  const handleGraphicImageChange = (event, setFormErrors, setFormValues) => {
    const files = Array.from(event.target.files);
    let isTooLarge = false;

    const validFiles = files.filter((file) => {
      if (file.size > maxSize) {
        setFormErrors((prevState) => ({
          ...prevState,
          graphic_images:
            "File size is too large (Compressed File must be under 10 MB).",
        }));
        isTooLarge = true;
        return false;
      }
      return true;
    });

    if (!isTooLarge) {
      const compressFiles = async (validFiles) => {
        const compressedFiles = await Promise.all(
          validFiles.map(async (file) => {
            return new Promise((resolve) => {
              //Compressor is async for success
              new Compressor(file, {
                quality: 0.2,
                success: (compressedResult) => {
                  resolve(compressedResult);
                },
              });
            });
          })
        );

        return compressedFiles;
      };

      const handleFileCompression = async (validFiles) => {
        try {
          validFiles = await compressFiles(validFiles);

          const newFiles = [];
          validFiles.forEach((blob) => {
            newFiles.push(
              new File([blob], blob.name, {
                type: blob.type,
              })
            );
          });

          const newImages = newFiles.map((file) => ({
            file,
            url: URL.createObjectURL(file),
            graphic: true,
          }));

          setFormValues((prevState) => {
            const updatedGraphicImages = [
              ...(prevState.graphic_images || []),
              ...newImages,
            ];

            return {
              ...prevState,
              graphic_images: updatedGraphicImages,
              error: "",
            };
          });

          const imagePreviews = newFiles.map((file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            return new Promise((resolve) => {
              reader.onloadend = () => {
                resolve(reader.result);
              };
            });
          });

          Promise.all(imagePreviews).then((previews) => {
            setGraphicImagePreviews((prevPreviews) => [
              ...prevPreviews,
              ...previews,
            ]);
          });
        } catch (error) {
          console.error("Error during file compression:", error);
        }
      };
      handleFileCompression(validFiles);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setImagePreviews((prevState) => arrayMove(prevState, oldIndex, newIndex));
    if (campaignType === "Animal") {
      setAnimalFormValues((prevState) => ({
        ...prevState,
        images: arrayMove(prevState.images, oldIndex, newIndex),
      }));
    } else if (campaignType === "Product") {
      setProductFormValues((prevState) => ({
        ...prevState,
        images: arrayMove(prevState.images, oldIndex, newIndex),
      }));
    } else if (campaignType === "Capital") {
      setCapitalFormValues((prevState) => ({
        ...prevState,
        images: arrayMove(prevState.images, oldIndex, newIndex),
      }));
    }
  };

  // Sets the campaign URL and sets QR modal open
  const handleCampaignSuccess = (url) => {
    const { protocol, host } = window.location;
    const fullUrl = `${protocol}//${host}${url}`;
    setCampaignUrl(fullUrl);
  };

  const enforce4Images = (formValues, errors) => {
    /* Enforce at least 4 total images
    Cases:
    - 4 normal > OK
    - Anything else is error if normal < 3 */

    if (campaignType === "Animal") {
      /* Enforce at least 4 total images
    Cases:
    - 4 normal, 0 graphic > OK
    - 3 normal, 1 graphic > OK
    - Anything else is error if normal < 3 */
      if (formValues.images.length < 4) {
        if (
          formValues.graphic_images.length === 0 ||
          (formValues.graphic_images.length > 0 && formValues.images.length < 3)
        ) {
          errors.images =
            "Please upload at least 4 normal images or 3 normal with 1 graphic image.";
          return false;
        }
      }
    } else {
      if (formValues.images.length < 4) {
        if (formValues.images.length <= 3) {
          errors.images = "Please upload at least 4 images";
          return false;
        }
      }
    }
    return true;
  };

  // Checks size of the image
  const checkImageSize = (formData, formValues, setFormErrors) => {
    if (campaignType === "Animal") {
      for (let i = 0; i < formValues.images.length; i++) {
        const image = formValues.images[i];
        if (image.file.size <= maxSize) {
          formData.append("images", image.file);
          formData.append(
            "orders",
            formValues.images.indexOf(formValues.images[i])
          );
        } else {
          setFormErrors((prevState) => ({
            ...prevState,
            image: "File size is too large. Please select smaller files.",
          }));
          return;
        }
      }
      for (let i = 0; i < formValues.graphic_images.length; i++) {
        const image = formValues.graphic_images[i];
        if (image.file.size <= maxSize) {
          formData.append("graphic_images", image.file);
          formData.append(
            "orders",
            formValues.graphic_images.indexOf(formValues.graphic_images[i])
          );
        } else {
          setFormErrors((prevState) => ({
            ...prevState,
            graphic_images:
              "File size is too large. Please select smaller files.",
          }));
          return;
        }
      }
    } else {
      for (let i = 0; i < formValues.images.length; i++) {
        const image = formValues.images[i];
        if (image.file.size <= maxSize) {
          formData.append("images", image.file);
          formData.append(
            "orders",
            formValues.images.indexOf(formValues.images[i])
          );
        } else {
          setFormErrors((prevState) => ({
            ...prevState,
            image: "File size is too large. Please select smaller files.",
          }));
          return;
        }
      }
    }

    return formData;
  };

  const appendFormValues = (formValues) => {
    const formData = new FormData();
    formData.append("nonprofit", nonprofitId);

    const formConfig = {
      animal: {
        formValues: animalFormValues,
        setFormErrors: setAnimalFormErrors,
      },
      product: {
        formValues: productFormValues,
        setFormErrors: setProductFormErrors,
      },
      capital: {
        formValues: capitalFormValues,
        setFormErrors: setCapitalFormErrors,
      },
    };

    const config = formConfig[campaignType];
    if (config) {
      const imageResponse = checkImageSize(
        formData,
        config.formValues,
        config.setFormErrors
      );
      if (!imageResponse) return null;
    }

    // Append allowed fields
    Object.keys(formValues).forEach((key) => {
      if (allowedFields.includes(key)) {
        if (key === "description") {
          formData.append(key, JSON.stringify(formValues[key])); // HTML as string to API
        } else if (
          key !== "images" &&
          key !== "graphic_images" &&
          key !== "error"
        ) {
          formData.append(key, formValues[key]);
        }
      }
    });

    // Append images and their orders
    const images = formValues.images || [];
    images.forEach((image, index) => {
      formData.append("images", image.file);
      formData.append("orders", index); // Use index as the order
    });

    // Append graphic images and their orders
    const graphicImages = formValues.graphic_images || [];
    graphicImages.forEach((graphicImage, index) => {
      formData.append("graphic_images", graphicImage.file);
      formData.append("graphic_img_order", images.length + index); // Continue ordering after standard images
    });
    return formData;
  };

  // Testing for required inputs
  const validateForm = (formValues, setFormErrors) => {
    const errors = {};

    // For all forms
    if (!formValues.campaign_title.trim()) {
      errors.campaign_title = "Campaign title is required";
    }
    if (!formValues.donation_goal) {
      errors.donation_goal = "Donation goal is required";
    }
    if (!formValues.description) {
      errors.description = "Description is required";
    }

    if (campaignType === "Animal") {
      if (!formValues.animal_name.trim()) {
        errors.animal_name = "Animal name is required";
      }
      if (!formValues.age) {
        errors.age = "Age is required";
      }
      if (!formValues.color.trim()) {
        errors.color = "Color is required";
      }
      if (!formValues.breed) {
        errors.breed = "Breed is required";
      }
      if (!formValues.gender) {
        errors.gender = "Gender is required";
      }
      if (!formValues.species) {
        errors.species = "Species is required";
      }

      enforce4Images(animalFormValues, errors);
    } else if (campaignType === "Product") {
      if (!formValues.product_type) {
        errors.product_type = "Product type is required";
      }
      if (!formValues.product_quantity_needed) {
        errors.product_quantity_needed = "Quantity is required";
      }
    } 

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if no errors
  };

  const handleAnimalSubmit = () => {
    if (validateForm(animalFormValues, setAnimalFormErrors)) {
      const formData = appendFormValues(animalFormValues);

      return formData;
    }
    setIsLoading(false); // Stop loading on validation failure
    return null; // Explicitly return null if validation fails
  };

  const handleProductSubmit = () => {
    if (validateForm(productFormValues, setProductFormErrors)) {
      const formData = appendFormValues(productFormValues);

      return formData;
    }

    setIsLoading(false); // Stop loading on validation failure
    return null; // Explicitly return null if validation fails
  };

  const handleCapitalSubmit = () => {
    if (validateForm(capitalFormValues, setCapitalFormErrors)) {
      const formData = appendFormValues(capitalFormValues);
      

      return formData;
    }

    setIsLoading(false); // Stop loading on validation failure
    return null; // Explicitly return null if validation fails
  };

  const handleCampaignSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formData;
    if (campaignType === "Animal") {
      formData = handleAnimalSubmit();
    } else if (campaignType === "Product") {
      formData = handleProductSubmit();
    } else if (campaignType === "Capital") {
      formData = handleCapitalSubmit();
    }
    
  

    if (formData) {
      try {
        const response = await submitCampaign(formData);

        if (response) {
          setSuccessMsg(
            'Campaign created successfully! Email your donor list from the "Dashboard"'
          );
          setCampaigns((prev) => [...prev, response]);

          if (campaignType === "Animal") {
            handleCampaignSuccess(
              `/campaigns/animal/${urlifyName(nonprofitName)}/${urlifyName(
                response.animal_name
              )}/${response.id}`
            );
          } else if (campaignType === "Product" || campaignType === "Capital") {
            const type = campaignType.toLowerCase();
            handleCampaignSuccess(
              `/campaigns/${type}/${urlifyName(nonprofitName)}/${urlifyName(
                response.campaign_title
              )}/${response.id}`
            );
          }
        }
      } catch (error) {
        console.error(error);
        setErrorMsg(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      return;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center z-50 overflow-auto p-4">
      <div className="bg-white rounded-lg p-6 max-w-xl w-full h-fit">
        <button onClick={onClose} className="text-2xl font-bold flex ml-auto">
          x
        </button>

        {campaignType === "Animal" ? (
          <AnimalCampaignForm
            nonprofitName={nonprofitName}
            nonprofitId={nonprofitId}
            onSuccess={handleCampaignSuccess}
            campaignUrl={campaignUrl}
            campaignData={campaignData}
            setCampaignData={setCampaignData}
            animalFormValues={animalFormValues}
            setAnimalFormValues={setAnimalFormValues}
            animalFormErrors={animalFormErrors}
            setAnimalFormErrors={setAnimalFormErrors}
            SortableList={SortableList}
            handleCampaignSubmit={handleCampaignSubmit}
            handleImageChange={handleImageChange}
            handleGraphicImageChange={handleGraphicImageChange}
            imagePreviews={imagePreviews}
            graphicImagePreviews={graphicImagePreviews}
            onSortEnd={onSortEnd}
            campaignType={campaignType}
            isLoading={isLoading}
          />
        ) : campaignType === "Product" ? (
          <ProductCampaignForm
            onSuccess={handleCampaignSuccess}
            campaignUrl={campaignUrl}
            productFormValues={productFormValues}
            setProductFormValues={setProductFormValues}
            productFormErrors={productFormErrors}
            setProductFormErrors={setProductFormErrors}
            SortableList={SortableList}
            handleCampaignSubmit={handleCampaignSubmit}
            handleImageChange={handleImageChange}
            imagePreviews={imagePreviews}
            onSortEnd={onSortEnd}
            campaignType={campaignType}
            isLoading={isLoading}
          />
        ) : campaignType === "Capital" ? (
          <CapitalCampaignForm
            onSuccess={handleCampaignSuccess}
            campaignUrl={campaignUrl}
            capitalFormValues={capitalFormValues}
            setCapitalFormValues={setCapitalFormValues}
            capitalFormErrors={capitalFormErrors}
            setCapitalFormErrors={setCapitalFormErrors}
            SortableList={SortableList}
            handleCampaignSubmit={handleCampaignSubmit}
            handleImageChange={handleImageChange}
            imagePreviews={imagePreviews}
            onSortEnd={onSortEnd}
            campaignType={campaignType}
            isLoading={isLoading}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CampaignModal;
