import React, { useState, useEffect, useRef } from "react";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import Compressor from "compressorjs";
import TextInput from "../../shared/Inputs/TextInput";
import DropDown from "../../shared/Inputs/Dropdown";
import classes from "./CampaignModal.module.css";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import SharedTooltip from "../../shared/Tooltip/SharedTooltip";
import Editor from "./Editor/Editor";
import CampaignFeature from "../../Campaigns/CampaignFeature/CampaignFeature";
import QRCodeModal from "../QRCodeModal/QRCodeModal";

const projectOptions = [
  { value: "planning", label: "Planning" },
  { value: "construction", label: "Under Construction" },
  { value: "completed", label: "Completed" },
];

const CapitalCampaignForm = ({
  nonprofitId,
  onSuccess,
  nonprofitName,
  qrModalOpen,
  campaignUrl,
  setQrModalOpen,
  capitalFormValues,
  setCapitalFormValues,
  capitalFormErrors,
  setCapitalFormErrors,
  SortableList,
  handleCampaignSubmit,
  handleImageChange,
  imagePreviews,
  onSortEnd,
  campaignType,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef();
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const finalValue =
      name === "project_status"
        ? projectOptions.find((option) => option.value.toString() === value)
            ?.value || value
        : value;

        // Prevent negative donaation goals
    if (name === "donation_goal" && parseFloat(value) < 0) {
      return;
    }

    setCapitalFormValues((prev) => ({
      ...prev,
      [name]: finalValue,
    }));

    // Restarts formErrors when input changes
    setCapitalFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleEditorChange = (content) => {
    setCapitalFormValues((prevState) => ({
      ...prevState,
      description: content,
    }));

    setCapitalFormErrors((prevState) => ({
      ...prevState,
      description: "",
    }));
  };

  const handleOpenPreview = () => {
    setPreviewModalOpen(true);

    const createCampaignContent = document.querySelector(
      `.${classes.campaign_modal_overlay}`
    );
    if (createCampaignContent) {
      createCampaignContent.style.overflow = "hidden";
    }
  };

  const handleClosePreview = () => {
    setPreviewModalOpen(false);

    const createCampaignContent = document.querySelector(
      `.${classes.campaign_modal_overlay}`
    );
    if (createCampaignContent) {
      createCampaignContent.style.overflow = "auto";
    }
  };

  return (
    <div>
      <h2 className="font-bold text-orange text-2xl text-center">
        Create New Capital Campaign
      </h2>
      <form onSubmit={handleCampaignSubmit}>
        <TextInput
          label="Campaign Title"
          subLabel="(Required)"
          type="text"
          name="campaign_title"
          placeholder="Campaign Title"
          value={capitalFormValues.campaign_title}
          onChange={handleInputChange}
          errorMessage={capitalFormErrors.campaign_title}
        />
        <TextInput
          label="Donation Goal"
          subLabel="(Required)"
          type="number"
          name="donation_goal"
          placeholder="$"
          value={capitalFormValues.donation_goal}
          onChange={handleInputChange}
          errorMessage={capitalFormErrors.donation_goal}
        />
        <TextInput
          label="Donation Box Title "
          maxLength="30"
          subLabel={
            <>
              (Max. 30 Chars){"  "}
              <SharedTooltip
                id="my-tooltip"
                toolClass="flex justify-center items-center"
                content="The heading right above the donation box"
              />
            </>
          }
          sublabelClassName="inline-flex items-center"
          type="text"
          name="donation_box_title"
          placeholder="Donate"
          value={capitalFormValues.donation_box_title}
          onChange={handleInputChange}
          errorMessage={capitalFormErrors.donation_box_title}
        />

        <div className="grid grid-cols-1 sm:grid-cols-[1fr,1fr] gap-2.5 items-end">
          <TextInput
            label="Land Area"
            type="text"
            name="land_area"
            placeholder="Land for 50 animals"
            value={capitalFormValues.land_area}
            onChange={handleInputChange}
            errorMessage={capitalFormErrors.land_area}
          />
          <TextInput
            label="Land Location"
            type="text"
            name="land_location"
            placeholder="Los Angeles, CA"
            value={capitalFormValues.land_location}
            onChange={handleInputChange}
            errorMessage={capitalFormErrors.land_location}
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-[1fr,1fr] gap-2.5 items-end">
          <DropDown
            label="Project Status"
            placeholder="Select an option"
            options={projectOptions}
            name="project_status"
            value={capitalFormValues.project_status}
            onChange={handleInputChange}
            errorMessage={capitalFormErrors.project_status}
            className="!w-full"
          />

          <TextInput
            label="Estimated Completion Date"
            type="date"
            name="estimated_completion_date"
            value={capitalFormValues.estimated_completion_date}
            onChange={handleInputChange}
            errorMessage={capitalFormErrors.estimated_completion_date}
            className="!w-full"
          />
        </div>

        <div>
          <label className="flex flex-row justify-start items-center gap-1.5">
            <h2 className="text-black font-roboto font-semibold text-lg block">
              Description
            </h2>
            <span className="text-grey-dark1 !text-sm font-roboto font-semibold">
              (Required)
            </span>
          </label>
          <Editor
            defaultValue={capitalFormValues.description}
            onTextChange={handleEditorChange}
          />
          <div className="mt-2 text-left w-full ">
            {capitalFormErrors.description !== "" && (
              <p className="text-error !text-sm">
                {capitalFormErrors.description}
              </p>
            )}
          </div>
        </div>
        <TextInput
          label="Video URLs"
          subLabel="(Separated by commas)"
          type="text"
          name="video_urls"
          placeholder="URL, URL"
          value={capitalFormValues.video_urls}
          onChange={handleInputChange}
          errorMessage={capitalFormErrors.video_urls}
        />
        {/* Image upload */}
        <div>
          <div className="">
            <h2 className="text-lg mb-4 !text-black font-semibold">Upload Images</h2>
          </div>
          <SortableList
            items={imagePreviews}
            onSortEnd={onSortEnd}
            axis="xy"
            helperClass={classes.sortable_helper}
          />

          <PrimaryButton
            className="!w-1/2 !justify-center !items-center !flex !m-auto !mt-4"
            onClick={(e) => {
              e.preventDefault();
              fileInputRef.current.click();
            }}
          >
            Add Image
          </PrimaryButton>
          <input
            onChange={(e) =>
              handleImageChange(e, setCapitalFormErrors, setCapitalFormValues)
            }
            multiple
            ref={fileInputRef}
            name="images"
            type="file"
            hidden
          />
          <div className="mt-2 text-center w-full ">
            {capitalFormErrors.images !== "" && (
              <p className="text-error !text-sm">{capitalFormErrors.images}</p>
            )}
          </div>
        </div>
        <div className="my-4 flex gap-2 justify-center items-center">
          <SecondaryButton type="submit" disabled={isLoading}>
            {isLoading ? "Saving..." : "Save Campaign"}
          </SecondaryButton>
          <PrimaryButton
            type="preview"
            onClick={(e) => {
              e.preventDefault();
              handleOpenPreview();
            }}
          >
            Preview
          </PrimaryButton>
        </div>
      </form>

      {previewModalOpen && (
        <CampaignFeature
          isOpen={previewModalOpen}
          onClose={() => handleClosePreview()}
          campaignData={capitalFormValues}
          imagePreviews={imagePreviews}
          graphicImagePreviews={[]}
          campaignType={campaignType}
        />
      )}

      <QRCodeModal
        isOpen={qrModalOpen}
        onClose={() => setQrModalOpen(false)}
        campaignUrl={campaignUrl}
      />
    </div>
  );
};

export default CapitalCampaignForm;
