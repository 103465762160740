import { useParams, useLocation } from "react-router";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCampaignbyId } from "../../../api/Campaigns";
import { faHeart, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { getNpInfo } from "../../../api/NonprofitInfo";
import Slider from "react-slick";
import { generateDonation } from "../../../api/GenerateDonation";
import { donationSuccess } from "../../../api/DonationSuccess";
import { parseDescription } from "../../shared/Utils/ParseDescription";
import SuccessModal from "./SuccessModal/SuccessModal";
import NotFound from "../../shared/Utils/NotFound";
import { useSelector } from "react-redux";
import LoginSignupModal from "../../shared/LoginSignupModal/LoginSignupModal";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import classes from "./CampaignFeature.module.css";
import DonationBox from "../../shared/DonationBox/DonationBox";
import CampaignDonorsBlock from "../CampaignDonorsBlock/CampaignDonorsBlock";
import { extractMissionStatement } from "../../shared/Utils/ExtractMissionStatement";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { cartActions } from "../../../store/index";
import { formatDate } from "../../shared/Utils/DateFilters";

const ageOptions = {
  Newborn: 1,
  Young: 2,
  Adult: 3,
  Senior: 4,
  Unknown: 5,
};

const productTypeOptions = {
  All: "all",
  Food: "food",
  Toys: "toys",
  Supplies: "supplies",
  Beds: "beds",
  "Medical Equipment": "medical",
  Other: "other",
};

const projectStatusOptions = {
  "All Statuses": "all",
  Planning: "planning",
  "Under Construction": "construction",
  Completed: "completed",
};

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

const DonationProgress = ({ campaign }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  // Calculate the progress percentage and remaining amount
  const progressPercentage = Math.min(
    (campaign.donation_amount / campaign.donation_goal) * 100,
    100
  ).toFixed(2);
  const remainingAmount =
    campaign.donation_goal - campaign.donation_amount < 0
      ? 0
      : campaign.donation_goal - campaign.donation_amount;

  // Function to handle link sharing
  const handleShareClick = () => {
    // Copy the current URL to the clipboard
    const shareLink = window.location.href;
    navigator.clipboard.writeText(shareLink);

    // Show tooltip
    setShowTooltip(true);

    // Hide tooltip after 3 seconds
    setTimeout(() => setShowTooltip(false), 3000);
  };

  return (
    <div className="border rounded-xl p-4 shadow-lg w-full relative">
      <div className="text-5xl my-4 mx-8 font-bold text-left text-blue">
        $
        {campaign.donation_amount
          ? campaign.donation_amount.toLocaleString()
          : "0"}
      </div>
      <div className="relative mt-2 mx-8">
        <div className="h-2 bg-gray-200 rounded-full">
          <div
            className="h-2 bg-blue rounded-full"
            style={{ width: `${progressPercentage}%` }}
          />
        </div>
      </div>
      <div className="flex justify-between text-sm mx-8 text-gray-700 mt-2">
        <span>Goal</span>
        <span>Remaining</span>
      </div>
      <div className="flex justify-between mx-8 text-lg font-semibold">
        <span>${campaign.donation_goal.toLocaleString()}</span>
        <span>${remainingAmount ? remainingAmount.toLocaleString() : "0"}</span>
      </div>
      <SecondaryButton onClick={handleShareClick} className="ml-8 mt-4">
        Share
        <FontAwesomeIcon icon={faHeart} className="ml-2 text-orange" />
      </SecondaryButton>
      {showTooltip && (
        <div className="flex transform mt-2 ml-8 bg-green-500 w-fit text-white text-sm font-semibold rounded px-2 py-1 shadow-lg">
          Link copied to clipboard!
        </div>
      )}
    </div>
  );
};

export default function CampaignFeature({
  isOpen,
  onClose,
  campaignData,
  imagePreviews,
  graphicImagePreviews,
  campaignType,
}) {
  const { nonprofit_name, animal_name, campaign_id } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [nonprofit, setNonprofit] = useState(null);
  const [npStatement, setNpStatement] = useState([]);
  const [ifReadMore, setIfReadMore] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [donationAmount, setDonationAmount] = useState(0);
  const [donationId, setDonationId] = useState(0);
  const [stripe, setStripe] = useState(null);
  const [elements, setElements] = useState(null);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [localUserData, setLocalUserData] = useState(null);
  const [isStripeLoading, setIsStripeLoading] = useState(true);
  const [blurredImages, setBlurredImages] = useState({});
  const [error, setError] = useState(null);
  const [coverFee, setCoverFee] = useState(true);
  const [prevAmount, setPrevAmount] = useState(0);
  const [customAmount, setCustomAmount] = useState("");
  const [showModal, setShowModal] = useState(false);
  const userData = useSelector((state) => state.userData.userData);

  // States for login/signup
  const [isLoginSignupModalOpen, setLoginSignupModalOpenModalOpen] =
    useState(false);
  const [loginOrSignup, setLoginOrSignup] = useState("");

  const [completedDonations, setCompletedDonations] = useState([]);

  // States for adding donation to cart
  const [cartError, setCartError] = useState("");
  const [showCartModal, setShowCartModal] = useState(false);

  // States for preview campaign feature
  const [ifPreview, setIfPreview] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [blurredPreviewImages, setBlurredPreviewImages] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Gets the type from the URLs
  // Requires using both due to animal campaigns having a different URL path
  const params = useParams();
  const location = useLocation();
  const type = location.pathname.includes("/animal/") ? "animal" : params.type;

  function terminalWarnings() {
    return isStripeLoading;
  }

  terminalWarnings();

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  // Handles and sets state for the Preview Modal
  useEffect(() => {
    if (isOpen) {
      setIfPreview(true);

      if (campaignData?.images) {
        const images = [
          ...imagePreviews.map((image) => ({
            image,
            graphic: false,
            show: true,
          })),
          ...graphicImagePreviews.map((image) => ({
            image,
            graphic: true,
            show: blurredPreviewImages[image] || false,
          })),
        ];

        setAllImages(images);
      }

      if (campaignData?.video_urls) {
        const regex = new RegExp(/^(https?:\/\/)/);
        const validateUrls = (urls) => {
          if (urls) {
            let validatedUrls = urls.split(",");
            for (let i = 0; i < validatedUrls.length; i++) {
              validatedUrls[i] = validatedUrls[i].trim();
              if (regex.test(validatedUrls[i])) {
                if (
                  validatedUrls[i].includes("youtube") &&
                  validatedUrls[i].indexOf("watch?v=") !== -1
                ) {
                  validatedUrls[i] = validatedUrls[i].replace(
                    "watch?v=",
                    "embed/"
                  );
                  validatedUrls[i] = validatedUrls[i].slice(
                    0,
                    validatedUrls[i].indexOf("&")
                  );
                }
              } else {
                validatedUrls[i] = "URL not valid";
              }
            }
            setVideoUrls(validatedUrls);
          }
        };

        validateUrls(campaignData.video_urls);
      }
    }
  }, [isOpen, blurredPreviewImages]);

  useEffect(() => {
    if (campaign?.campaign_images) {
      campaign.campaign_images.forEach((image) => {
        if (image.graphic === true) {
          setBlurredImages((prevState) => ({
            ...prevState,
            [image.image_url]: true, // Set the initial value to true (blurred)
          }));
        }
      });
    }
  }, [campaign]);

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const tempLoadedCampaign = await getCampaignbyId(campaign_id, type);

        if (!tempLoadedCampaign) {
          throw new Error("Campaign not found");
        }
        if (!tempLoadedCampaign) return;
        const tempLoadedNonprofit = await getNpInfo(
          tempLoadedCampaign.nonprofit
        );

        const donations = tempLoadedCampaign.donations_details.filter(
          (donation) => {
            return donation["status"] === "completed";
          }
        );
        const sentences = extractMissionStatement(
          tempLoadedNonprofit.mission_stmt,
          true
        );
        setNpStatement(sentences);
        setCampaign(tempLoadedCampaign);
        setNonprofit(tempLoadedNonprofit);
        setCompletedDonations(donations);
        return tempLoadedCampaign;
      } catch (error) {
        if (ifPreview) {
          setError(null);
        } else {
          setError(error);
        }
      }
    };
    if (!isOpen) {
      try {
        fetchAll();
      } catch (error) {
        console.error("Error in CampaignFeature:", error);
      }
    }
  }, [nonprofit_name, animal_name, campaign_id]);

  const handleDonationSubmit = async (event) => {
    event.preventDefault();

    let amountToDonate = selectedOption || parseFloat(customAmount);

    if (coverFee) {
      amountToDonate += parseFloat((amountToDonate * 0.03).toFixed(2));
    }

    setDonationAmount(amountToDonate);

    const initializePayment = async (campaign) => {
      if (!campaign || !campaign.donation_goal) return;

      const data = {
        campaign_id: campaign_id,
        campaign_type: campaign.campaign_type,
        amount: amountToDonate,
      };

      try {
        const response = await generateDonation(data);

        setClientSecret(response.client_secret);
        setDonationId(response.donation_id);
      } catch (error) {
        console.error("Error: ", error);
      }
    };

    initializePayment(campaign);
  };

  useEffect(() => {
    if (clientSecret) {
      const stripeInstance = window.Stripe(process.env.REACT_APP_STRIPE_KEY);
      const appearance = {
        /* appearance */
      };
      const options = {
        layout: {
          type: "tabs",
          defaultCollapsed: false,
        },
      };
      const elementsInstance = stripeInstance.elements({
        clientSecret,
        appearance,
      });
      const cardElement = elementsInstance.create("payment", options);
      cardElement.mount("#payment-element");

      cardElement.on("ready", () => {
        setIsStripeLoading(false);
      });

      setStripe(stripeInstance);
      setElements(elementsInstance);
    }
  }, [clientSecret]);

  // Shows blurred previews images
  const handlePreviewImageClick = (image) => {
    setBlurredPreviewImages((prevState) => ({
      ...prevState,
      [image.image]: true,
    }));
  };

  const handleSubmitPayment = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const paymentElement = elements.getElement("payment");

    if (!paymentElement) {
      setPaymentResponse("Payment Element is not loaded.");
      return;
    }

    setIsStripeLoading(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: "if_required",
      });

      if (error) {
        setPaymentResponse(error.message);
      } else {
        if (paymentIntent && paymentIntent.status === "succeeded") {
          setPaymentResponse({ status: "success" });
          setShowModal(true);
          await donationSuccess({
            donation_id: donationId,
            campaign_type: campaign.campaign_type,
          });
        } else {
          setPaymentResponse({ error: error });
        }
      }
    } catch (error) {
      setPaymentResponse("An error occurred during payment.");
      console.error(error);
    } finally {
      setIsStripeLoading(false);
    }
  };

  const handleOptionClick = (amount) => {
    if (customAmount) {
      setCoverFee(false);
      setCustomAmount("");
    }

    if (selectedOption === amount) {
      setSelectedOption(null);
      setPrevAmount(0);
      setDonationAmount(0);
    } else {
      setSelectedOption(amount);
      setPrevAmount(amount);
      setDonationAmount(amount);
      setCoverFee(false);
    }
  };

  const handleImageClick = (imageUrl) => {
    setBlurredImages((prevState) => ({
      ...prevState,
      [imageUrl]: prevState[imageUrl] === false ? true : false,
    }));
  };

  const handleInputChange = (e) => {
    setPrevAmount(e.target.value);
    setCustomAmount(e.target.value);
    setDonationAmount(parseFloat(e.target.value));
    if (selectedOption) {
      setSelectedOption(null);
      setCoverFee(false);
    }
  };

  const handleCoverFeeChange = (e) => {
    setCoverFee(e.target.checked);
    const fee = parseFloat((prevAmount * 0.03).toFixed(2));
    if (e.target.checked) {
      setDonationAmount(Number(prevAmount) + Number(fee));
    } else {
      setDonationAmount(Number(prevAmount));
    }
  };

  let slideshowSettings = {
    dots: true,
    infinite: ifPreview
      ? (allImages.length > 0 || videoUrls.length > 0) &&
        allImages.length + videoUrls.length > 1
      : campaign && campaign.campaign_images
      ? campaign.campaign_images.length > 1
      : false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const closeModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  if (error) {
    return <NotFound />;
  }

  const openLoginSignupModal = (type) => {
    setLoginOrSignup(type);
    setLoginSignupModalOpenModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeLoginSignupModal = () => {
    setLoginSignupModalOpenModalOpen(false);
    document.body.style.overflow = "";
  };

  const handleFormSuccess = (success) => {
    if (success) {
      window.location.reload();
    }
  };

  const handleAddToCart = (e) => {
    e.preventDefault();

    setCartError("");

    if (donationAmount > 0) {
      const cartData = {
        donation_id: campaign.id,
        amount: donationAmount,
        name: campaign.animal_name || campaign.campaign_title,
        nonprofit_name: campaign.nonprofit_name,
        image: campaign.campaign_images.find(
          (image) => image.graphic === false
        ),
        campaign_type: campaign.campaign_type,
        campaign_id: campaign.id,
        nonprofit_id: campaign.nonprofit,
      };

      try {
        // Update the global cart state
        dispatch(cartActions.addDonationToCart(cartData));

        // Open the success cart modal
        setShowCartModal(true);
      } catch (error) {
        setCartError("Unable to add to cart. Please try again.");
        console.error("Error adding item to cart:", error);
      }
    } else {
      setCartError("Please select or add an amount.");
      console.error("No donation amount selected.");
    }
  };

  const closeCartModal = () => {
    setShowCartModal(false);
  };

  if ((!campaign || !nonprofit) && !ifPreview) {
    return (
      <div className="flex flex-row ml-auto mt-[35vh] w-full h-full justify-center">
        <Spinner />
      </div>
    );
  }

  // Handles switching from 'Read more' to 'Read less'
  const handleReadMoreButton = () => {
    return ifReadMore ? setIfReadMore(false) : setIfReadMore(true);
  };

  const dataSource = campaignData || campaign;

  return (
    <div
      className={`w-full h-full pb-8 ${
        ifPreview
          ? "relative main-div overflow-auto box-border"
          : "px-4 sm:px-8 2xl:px-36"
      }`}
    >
      <div
        className={`w-full h-full ${
          ifPreview
            ? "!fixed top-0 left-0 z-50 bg-white overflow-y-auto !m-0 pt-0 px-4 sm:px-16"
            : ""
        }`}
      >
        {/* Top section of Preview Campaign */}
        {ifPreview && (
          <section
            className={`md:flex-col md:h-96 flex lg:flex-row p-0 pb-4 w-full lg:h-16 mb-16`}
          >
            <h1
              className={`md:w-4/5 md:text-left md:ml-4 md:pt-4 md:pb-4 cursor-pointer lg:w-1/5 text-orange lg:p-0 lg:pb-4`}
              onClick={() => {
                onClose();
                setIfPreview(false);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Exit Preview
            </h1>
            <div
              className={`flex items-center justify-center grow w-full bg-stone-400 text-white`}
            >
              <p>This is a preview of your campaign.</p>
            </div>
          </section>
        )}

        <div
          className={`flex flex-col lg:flex-row justify-between gap-8 w-full mt-24  ${
            ifPreview ? "!mt-0" : ""
          }`}
        >
          {/* First Column - 60% Width */}
          <div className=" flex flex-col mt-12 w-full lg:w-[60%]">
            {ifPreview && (
              <h1 className="text-left !text-orange">
                {campaignData.animal_name}
              </h1>
            )}
            {campaign && (
              <h1 className="text-left !text-orange">{campaign.animal_name}</h1>
            )}
            {ifPreview ? (
              allImages.length > 0 || videoUrls.length > 0 ? (
                <Slider
                  {...slideshowSettings}
                  className={`${classes.campaign_media_carousel} relative w-100 h-[40vh] flex items-center justify-center`}
                >
                  {allImages.length > 0 &&
                    allImages.map((img, index) => (
                      <div
                        key={index}
                        className="w-100 h-[40vh] flex items-center justify-center relative"
                      >
                        <img
                          src={img.image}
                          alt={campaignData.animal_name}
                          className={`object-scale-down w-full h-full ${
                            img.graphic && !img.show
                              ? "cursor-pointer blur-md"
                              : ""
                          }`}
                        />
                        {img.graphic && !img.show && (
                          <PrimaryButton
                            onClick={() => handlePreviewImageClick(img)}
                            className="!absolute !top-1/2 !left-1/2 !transform !-translate-x-1/2 !-translate-y-1/2"
                          >
                            Show
                          </PrimaryButton>
                        )}
                      </div>
                    ))}
                  {videoUrls.length > 0 &&
                    videoUrls.map((url, index) => (
                      <div
                        key={index}
                        className="w-100 h-[40vh] flex items-center justify-center"
                      >
                        {url !== "URL not valid" ? (
                          <iframe
                            src={url}
                            title={`Success Video ${index + 1}`}
                            frameBorder="0"
                            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                            className="object-scale-down w-full h-full"
                          />
                        ) : (
                          <p className="flex justify-center items-center w-full h-full text-red">
                            Invalid URL
                          </p>
                        )}
                      </div>
                    ))}
                </Slider>
              ) : (
                "" // Renders empty string if ifPreview is true and there aren't any images/videos
              )
            ) : campaign?.campaign_images &&
              campaign.campaign_images.length > 0 ? (
              // Renders campaign images and videos if ifPreview is false
              <Slider
                {...slideshowSettings}
                className={`${classes.campaign_media_carousel} relative w-100 h-[40vh] flex items-center justify-center`}
              >
                {campaign.campaign_images
                  .sort((a, b) => a.order - b.order)
                  .map((image, index) => (
                    <div
                      key={index}
                      className="w-100 h-[40vh] flex items-center justify-center"
                    >
                      <div className="relative w-100 h-[40vh]">
                        <img
                          src={image.image_url}
                          alt={campaign.animal_name}
                          className={`object-scale-down w-full h-full ${
                            image.graphic && blurredImages[image.image_url]
                              ? "cursor-pointer blur-md"
                              : ""
                          }`}
                        />
                        {image.graphic && blurredImages[image.image_url] && (
                          <PrimaryButton
                            onClick={() => handleImageClick(image.image_url)}
                            className="!absolute !top-1/2 !left-1/2 !transform !-translate-x-1/2 !-translate-y-1/2"
                          >
                            Show
                          </PrimaryButton>
                        )}
                      </div>
                    </div>
                  ))}
                {campaign.video_urls &&
                  campaign.video_urls.split(",").map((videoUrl, index) => (
                    <div
                      key={index}
                      className="w-100 h-[40vh] flex items-center justify-center"
                    >
                      <iframe
                        src={videoUrl.trim()}
                        title={`Campaign Video ${index + 1}`}
                        frameBorder="0"
                        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        className="object-scale-down w-full h-full"
                      />
                    </div>
                  ))}
              </Slider>
            ) : null}

            {/* Campaign Description */}
            <div>
              {ifPreview || (campaign && campaign.description) ? (
                <>
                  <div className="flex flex-col">
                    <h1 className="!text-orange text-left my-8">
                      {ifPreview
                        ? type === "animal"
                          ? `About ${
                              campaignData?.animal_name || "Animal Name"
                            }`
                          : `${
                              campaignData?.campaign_title || "Campaign Title"
                            }`
                        : type === "animal"
                        ? `About ${campaign?.animal_name || "Animal Name"}`
                        : `${campaign?.campaign_title || "Campaign Title"}`}
                    </h1>

                    {ifPreview ? (
                      campaignData?.description ? (
                        parseDescription === campaignData.description ? (
                          <p className="text-left">
                            {campaignData.description}
                          </p>
                        ) : (
                          <div
                            className="text-left"
                            dangerouslySetInnerHTML={{
                              __html: parseDescription(
                                campaignData.description
                              ),
                            }}
                          />
                        )
                      ) : (
                        <p>Description</p>
                      )
                    ) : parseDescription === campaign.description ? (
                      <p className="text-left">{campaign.description}</p>
                    ) : (
                      <div
                        className="text-left"
                        dangerouslySetInnerHTML={{
                          __html: parseDescription(campaign.description),
                        }}
                      />
                    )}
                  </div>
                  {campaignType === "Animal" || type === "animal" ? (
                    <div className="text-left mt-8">
                      <p className="font-semibold">
                        Age:{" "}
                        <span className="font-normal">
                          {getKeyByValue(ageOptions, dataSource?.age)}
                        </span>
                      </p>
                      <p className="font-semibold">
                        Color:{" "}
                        <span className="font-normal">{dataSource?.color}</span>
                      </p>
                      <p className="font-semibold">
                        Breed:{" "}
                        <span className="font-normal">{dataSource?.breed}</span>
                      </p>
                      <p className="font-semibold">
                        Gender:{" "}
                        <span className="font-normal">
                          {dataSource?.gender}
                        </span>
                      </p>
                      <p className="font-semibold">
                        Species:{" "}
                        <span className="font-normal">
                          {dataSource?.species}
                        </span>
                      </p>
                    </div>
                  ) : campaignType === "Product" || type === "product" ? (
                    <div className="text-left">
                      <p className="font-semibold">
                        Product type:{" "}
                        <span className="font-normal">
                          {getKeyByValue(
                            productTypeOptions,
                            dataSource?.product_type
                          )}
                        </span>
                      </p>
                      <p className="font-semibold">
                        Quantity:{" "}
                        <span className="font-normal">
                          {dataSource?.product_quantity_needed}
                        </span>
                      </p>
                    </div>
                  ) : campaignType === "Capital" || type === "capital" ? (
                    <div className="text-left">
                      <p className="font-semibold">
                        Area:{" "}
                        <span className="font-normal">
                          {dataSource?.land_area}
                        </span>
                      </p>
                      <p className="font-semibold">
                        Location:{" "}
                        <span className="font-normal">
                          {dataSource?.land_location}
                        </span>
                      </p>
                      <p className="font-semibold">
                        Project Status:{" "}
                        <span className="font-normal">
                          {getKeyByValue(
                            projectStatusOptions,
                            dataSource?.project_status
                          )}
                        </span>
                      </p>
                      <p className="font-semibold">
                        Estimated Completion Date:{" "}
                        <span className="font-normal">
                          {formatDate(dataSource?.estimated_completion_date)}
                        </span>
                      </p>
                    </div>
                  ) : null}
                </>
              ) : (
                ""
              )}
            </div>

            <hr className="mt-8 bg-gray-900 border-t-2" />

            {nonprofit && (
              <h1 className="!text-orange text-center mt-8">
                {nonprofit.org_name}
              </h1>
            )}
            {npStatement.length > 3 ? (
              <div className="flex flex-col justify-center items-center">
                <p>{ifReadMore ? npStatement : npStatement.slice(0, 3)}</p>
                <SecondaryButton
                  className="!w-1/3 !mt-4"
                  onClick={() => handleReadMoreButton()}
                >
                  {ifReadMore ? "Read less.." : "Read more..."}
                </SecondaryButton>
              </div>
            ) : (
              <p>{npStatement}</p>
            )}
            {nonprofit?.intro_video && (
              <div className="!w-full !h-[50vh] border border-orange mt-8 mx-auto rounded-3xl flex items-center justify-center">
                <iframe
                  src={nonprofit.intro_video}
                  title="Campaign Intro"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="object-scale-down w-full h-full rounded-3xl"
                />
              </div>
            )}
            <div className="flex items-center justify-center">             
                <DonationBox nonprofit={nonprofit} className />
            </div>
          </div>

          {/* Second Column - 40% Width */}
          <div className="flex flex-col my-12 w-full lg:w-[40%] gap-12">
            {ifPreview ? (
              <DonationProgress campaign={campaignData} />
            ) : (
              <DonationProgress campaign={campaign} />
            )}

            
              <div className="flex flex-col border my-12 w-full rounded-3xl border-gray shadow-lg border-1">
                <h1 className="my-8 !text-orange break-all mx-4">
                  {ifPreview
                    ? campaignData?.donation_box_title || "Donate"
                    : campaign?.donation_box_title || "Donate"}
                </h1>

                {!ifPreview && !localUserData && (
                  <div>
                    <h4 className="mb-9">
                      {isLoginSignupModalOpen && (
                        <LoginSignupModal
                          loginOrSignup={loginOrSignup}
                          isOpen={openLoginSignupModal}
                          onClose={closeLoginSignupModal}
                          onFormSuccess={handleFormSuccess}
                        />
                      )}
                      <button
                        className="underline text-orange"
                        onClick={() => openLoginSignupModal("signup")}
                      >
                        Sign Up
                      </button>{" "}
                      or{" "}
                      <button
                        className="underline text-orange"
                        onClick={() => openLoginSignupModal("login")}
                      >
                        login
                      </button>{" "}
                      to donate here
                    </h4>
                  </div>
                )}

                {!ifPreview && paymentResponse?.status && showModal && (
                  <SuccessModal
                    message="Payment succeeded!"
                    onClose={closeModal}
                    showModal={showModal}
                  />
                )}

                {/* Donation box */}
                <div className="w-full flex flex-col justify-center items-center mb-4 rounded-lg">
                  <div className="flex flex-col justify-center items-center">
                    {!clientSecret && (
                      <div className="grid grid-cols-3 gap-8 px-2">
                        {[10, 25, 35, 50, 75, 100].map((amount) => (
                          <button
                            key={amount}
                            className={`${
                              selectedOption === amount
                                ? "!bg-orange !text-white"
                                : "hover:bg-gray-200"
                            } border border-gray-400 !font-semibold text-neutral-600 rounded-2xl py-2.5 px-4 box:px-6`}
                            type="button"
                            disabled={ifPreview}
                            onClick={
                              !ifPreview
                                ? () => handleOptionClick(amount)
                                : undefined
                            }
                          >
                            ${amount}
                          </button>
                        ))}
                      </div>
                    )}
                    {!clientSecret ? (
                      <form
                        id={classes.amount_form}
                        className="w-full no-underline"
                        onSubmit={!ifPreview ? handleDonationSubmit : undefined}
                      >
                        <div className="my-12 px-2">
                          <input
                            type="number"
                            id="donation-amount"
                            name="donation-amount"
                            placeholder="Custom donation amount"
                            className="border border-gray-400 p-2 rounded-3xl w-80"
                            value={customAmount}
                            onChange={
                              !ifPreview ? handleInputChange : undefined
                            }
                            disabled={ifPreview}
                            min={1}
                            onWheel={(e) => e.target.blur()} // prevents scrolling
                          />
                          <div className="flex flex-row gap-2 mt-2 pl-4 pr-4">
                            <label htmlFor="coverFee" className="text-sm">
                              Would you like to cover the transaction fee of ($
                              {(prevAmount * 0.03).toFixed(2)})?
                            </label>
                            <div className="-mt-4">
                              <input
                                type="checkbox"
                                id="coverFee"
                                checked={!ifPreview ? coverFee : undefined}
                                onChange={
                                  !ifPreview ? handleCoverFeeChange : undefined
                                }
                                disabled={ifPreview}
                              />
                            </div>
                          </div>

                          <div className="flex gap-4">
                            <PrimaryButton
                              id="submit-amount"
                              type="submit"
                              className={`!w-36 !mx-auto ${
                                !ifPreview &&
                                !localUserData &&
                                "!bg-grey cursor-not-allowed"
                              }`}
                              disabled={!ifPreview && !localUserData}
                            >
                              Donate
                            </PrimaryButton>
                            <SecondaryButton
                              type="button"
                              className="!w-36 !mx-auto"
                              onClick={handleAddToCart}
                            >
                              Add to Cart
                            </SecondaryButton>
                          </div>
                          <div className="mx-auto mt-4">
                            {cartError && (
                              <p className="text-error">{cartError}</p>
                            )}
                          </div>
                        </div>
                      </form>
                    ) : (
                      <form
                        id="payment-form"
                        className={classes.payment_form}
                        onSubmit={handleSubmitPayment}
                      >
                        <div id="payment-element"></div>
                        <PrimaryButton type="submit" className="mt-4 mx-auto">
                          Complete Donation
                        </PrimaryButton>
                      </form>
                    )}

                    {/* Modal to show that the item has been added to the cart */}
                    {showCartModal && (
                      <SuccessModal
                        message="Donation added to cart!"
                        onClose={closeCartModal}
                        showCartModal={showCartModal}
                      />
                    )}
                  </div>
                </div>
              </div>
            

            {campaign && <CampaignDonorsBlock campaign={campaign} />}
          </div>
        </div>
      </div>
    </div>
  );
}
